import HttpRequest from './core/http_request';

class AdminProvider extends HttpRequest {
  /* Organize manage */
  getOrganize (data) {
    return this.request('get', '/organization/fulltree', data);
  }

  getOrganizeFullTreeByID (data) {
    return this.request('get', '/organization/fulltree/?organizationID=' + data._id, data);
  }

  getOrganizeData (data) {
    return this.request('get', '/organization/id/' + data._id, data);
  }

  addOrganize (data) {
    return this.request('post', '/organization', data);
  }

  updateOrganize (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/organization/id/' + id, data);
  }

  getOrganizeCode (data) {
    return this.request('get', '/organization/code/' + data.code, data);
  }

  getFullChild (data) {
    return this.request('get', '/organization/fullchild/' + data.organizationID, data);
  }
  /* End Organize manage */

  /* User manage */
  getUserList (data) {
    return this.request('get', '/member', data);
  }

  addUser (data) {
    return this.request('post', '/member', data);
  }

  updateUser (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/member/id/' + id, data);
  }

  getUser (data) {
    const id = data._id;
    return this.request('get', '/member/id/' + id, data);
  }

  updatePermissionByOrgId (data) {
    return this.request('post', '/organization/resetMemberPermission/' + data.id);
  }

  getAdminList (data) {
    return this.request('get', '/organization/getadminlist/' + data.organizationID, data);
  }

  getMemberList (data) {
    return this.request('get', '/organization/getmemberlist/' + data.organizationID, data);
  }
  /* End User manage */

  /* screen manage */
  getScreenList (data) {
    return this.request('get', '/screen', data);
  }

  getScreen (data) {
    const id = data._id;
    return this.request('get', '/screen/id/' + id, data);
  }

  addScreen (data) {
    return this.request('post', '/screen', data);
  }

  updateScreen (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/screen/id/' + id, data);
  }

  deleteScreen (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/screen/id/' + id, data);
  }

  uploadScreenFile (data) {
    return this.request('upload', '/uploadFile/uploadScreenFile', data);
  }

  getFullParentOrganization (data) {
    return this.request('get', '/organization/fullparent/' + data._id);
  }

  updateScreenPosition (data) {
    return this.request('post', '/screen/updatePosition', data);
  }

  // citizen zone -----------------------------------------------------------------------
  uploadCitizenPost (data) {
    return this.request('upload', '/uploadFile/uploadCitizenPostFile', data);
  }

  getCitizenPost (data) {
    return this.request('get', '/citizenpost', data);
  }

  addCitizenPost (data) {
    return this.request('post', '/citizenpost', data);
  }

  getCitizenPostByID (data) {
    const id = data._id;
    return this.request('get', '/citizenpost/id/' + id);
  }

  updateCitizenPost (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/citizenpost/id/' + id, data);
  }

  deleteCitizenPost (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/citizenpost/delete/' + id);
  }

  // trainingVdo Zone
  uploadTrainingVdo (data) {
    return this.request('upload', '/uploadFile/uploadTrainingVdoFile', data);
  }

  getTrainingVdo (data) {
    return this.request('get', '/trainingvdo', data);
  }

  addTrainingVdo (data) {
    return this.request('post', '/trainingvdo', data);
  }

  getTrainingVdoByID (data) {
    const id = data._id;
    return this.request('get', '/trainingvdo/id/' + id);
  }

  updateTrainingVdo (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/trainingvdo/id/' + id, data);
  }

  deleteTrainingVdo (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/trainingvdo/delete/' + id);
  }

  //carlendar zone
  updateCalendar () {
    return this.request('post', '/calendar/updateCalendar');
  }

  getlistCalendar (data) {
    return this.request('get', '/calendar/', data);
  }

  /* end screen manage */
}

export default AdminProvider;
