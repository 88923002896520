<style>
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap');
</style>

<template>
  <v-app :theme="theme">
    <v-navigation-drawer
      v-model="sidebarMenu"
      floating
      :permanent="sidebarMenu"
      v-if="!isHideHeader"
      class="nav-top"
      style="height: 50px !important;"
      :class="{'nav-top-bg': scrollPosition > 0}"
      touchless
    >
      <ul class="navMenu v-container">
        <v-menu class="mobileNavMenu">
          <template #activator="{ props }">
            <v-btn
              color="primary"
              v-bind="props"
              icon="mdi-menu"
              class="d-inline-block d-md-none d-lg-none d-xl-none mr-3"
              variant="flat"
            >
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(menu, index) in getMenuList"
              :key="index"
              :class="{'active': ($route.name == menu.routerName)}"
              v-show="menu.isShow"
            >
              <v-list-item-title><a :href="'/'+menu.routerName">{{ menu.name }}</a></v-list-item-title>
            </v-list-item>
            <!-- <v-divider></v-divider>
            <v-list-item class="leftMenuMobile displayUsername">
              <v-list-item-title style="font-weight: bold; text-align: center;">
                <a
                  class=""
                  href="javascript:void(0);"
                >
                  {{ getUsername }}
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="leftMenuMobile">
              <v-list-item-title>
                <a
                  class=""
                  href="/profile"
                  style="font-size: 14px;"
                >
                  <v-icon
                    left
                    dark
                  >
                    mdi-account-edit
                  </v-icon>
                  ข้อมูลส่วนตัว
                </a>
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="leftMenuMobile">
              <v-list-item-title>
                <a
                  class=""
                  href="javascript:void(0)"
                  @click="logout"
                  style="font-size: 14px;"
                >
                  <v-icon
                    left
                    dark
                  >
                    mdi-logout-variant
                  </v-icon>
                  ออกจากระบบ
                </a>
              </v-list-item-title>
            </v-list-item> -->
          </v-list>
        </v-menu>

        <li class="headMenuLogo pl-0">
          <img src="/img/headmenu/headlogo.svg">
        </li>

        <li
          v-for="(menu, index) in getMenuList"
          :key="index"
          :class="{'active': ($route.name == menu.routerName)}"
          v-show="menu.isShow"
          class="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block"
        >
          <a
            :href="'/'+menu.routerName"
            v-show="menu.isShow"
          > {{ menu.name }} </a>
        </li>
        <div v-show="false">
          {{ getUsername }}
        </div>
        <!-- <li
          class="float-right profileMenu pr-0"
          v-if="!isGuest"
        > -->
        <!-- <li
          class="float-right profileMenu pr-0"
        >
          <v-menu open-on-hover>
            <template #activator="{ props }">
              <v-btn
                variant="text"
                class="profileDropdownBtn pr-0"
                v-bind="props"
              >
                <img
                  src="/img/transparant/300x300.png"
                  class="profileImage"
                  :style="{ backgroundImage: `url(${profileImg})` }"
                >
                <v-icon
                  right
                  dark
                >
                  mdi-menu-down
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title style="text-align: center;">
                  <a
                    class="hrefInDropdown font-weight-bold"
                    href="javascript:void(0);"
                    style="font-size: 1rem;"
                  >
                    {{ getUsername }}
                  </a>
                </v-list-item-title>
                <v-divider></v-divider>
                <v-list-item-title>
                  <a
                    class="hrefInDropdown"
                    href="/profile"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-account-edit
                    </v-icon>
                    ข้อมูลส่วนตัว
                  </a>
                </v-list-item-title>
                <v-divider></v-divider>
                <v-list-item-title>
                  <a
                    class="hrefInDropdown"
                    href="javascript:void(0)"
                    @click="logout"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-logout-variant
                    </v-icon>
                    ออกจากระบบ
                  </a>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </li> -->

        <div class="headerMenuProfile float-right profileMenu pr-0">
          <!-- <v-menu open-on-hover> -->
          <v-menu>
            <template #activator="{ props }">
              <v-btn
                variant="text"
                class="profileDropdownBtn pr-0"
                v-bind="props"
              >
                <img
                  src="/img/transparant/300x300.png"
                  class="profileImage"
                  :style="{ backgroundImage: `url(${profileImg})` }"
                >
                <!-- <v-icon
                  right
                  dark
                >
                  mdi-menu-down
                </v-icon> -->
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title style="text-align: center;">
                  <a
                    class="hrefInDropdown font-weight-bold"
                    href="javascript:void(0);"
                    style="font-size: 1rem;"
                  >
                    {{ getUsername }}
                  </a>
                </v-list-item-title>
                <v-divider></v-divider>
                <v-list-item-title>
                  <a
                    class="hrefInDropdown"
                    href="/profile"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-account-edit
                    </v-icon>
                    ข้อมูลส่วนตัว
                  </a>
                </v-list-item-title>
                <v-divider></v-divider>
                <v-list-item-title>
                  <a
                    class="hrefInDropdown"
                    href="javascript:void(0)"
                    @click="logout"
                  >
                    <v-icon
                      left
                      dark
                    >
                      mdi-logout-variant
                    </v-icon>
                    ออกจากระบบ
                  </a>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </ul>
    </v-navigation-drawer>
    <v-content :class="setContentClass">
      <v-container class="fill-height align-stretch">
        <v-row class="fill-height">
          <v-col class="pa-0">
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </v-col>
        </v-row>
      </v-container>
    </v-content>

    <!-- <v-footer
      app
      class="ml-0 py-3 gus_bgBlackTp w-100"
      v-if="!isHideFooter"
    >
      <span class="ml-auto overline">By C-Site Reporter &copy;2022</span>
    </v-footer> -->
  </v-app>
</template>

<script>
import './assets/sass/style.scss';
import { ref } from 'vue';

export default {
  setup () {
    const theme = ref('csiteTheme');

    return {
      theme,
      toggleTheme: () => {
        console.log(theme);
        theme.value = theme.value === 'light' ? 'dark' : 'light';
      }
    };
  },
  css: {
    loaderOptions: {
      sass: {
        data: `@import "@/assets/sass/style.scss";`
      }
    }
  },
  data: () => ({
    sidebarMenu: true,
    toggleMini: false,
    lang: true,
    scrollPosition: 0,
    memberData: {
      username: 'Username'
    },
    isAdmin: false,
    menuList: [{
      name: 'แบบสำรวจ',
      permission: '',
      routerName: 'mysurvey',
      isShow: false
    }, {
      name: 'แบบสำรวจ (ผู้ดูแลระบบ)',
      permission: 'admin',
      routerName: 'adminsurvey',
      isShow: false
    },
    {
      name: 'จัดการหน่วยงาน',
      permission: 'admin',
      routerName: 'organizemanagement',
      isShow: false
    }, {
      name: 'จัดการผู้ใช้งาน',
      permission: 'admin',
      routerName: 'usermanagement',
      isShow: false
    }, {
      name: 'จัดการวิทยาศาสตร์พลเมือง',
      permission: 'superAdmin',
      routerName: 'citizenpostmanagement',
      isShow: false
    },
    {
      name: 'จัดการคลิปวิดีโออบรม',
      permission: 'superAdmin',
      routerName: 'trainingvdomanagement',
      isShow: false
    },
    {
      name: 'ปฏิทินกิจกรรม',
      permission: 'superAdmin',
      routerName: 'calendarmanagement',
      isShow: false
    }],
    currRouter: null,
    profileImg: '/img/headmenu/anonymous.png',
    isGuest: true
  }),
  methods: {
    updateScroll () {
      this.scrollPosition = window.scrollY;
    },

    logout () {
      localStorage.removeItem('member');
      localStorage.removeItem('token');
      this.$router.push('/');
    },

    // initMemberData () {
    //   if (localStorage.getItem('member') !== undefined
    //   && localStorage.getItem('member') !== null) {
    //     const data = localStorage.getItem('member');
    //     const memberData = JSON.parse(data);
    //     this.username = memberData.username;
    //     console.log(this.username);
    //   }
    // },

    getMemberData () {
      if (localStorage.getItem('member')) {
        const data = localStorage.getItem('member');
        return JSON.parse(data);
      } else {
        return null;
      }
    }
  },
  computed: {
    isHideHeader () {
      if (this.$route.name === 'Login' ||
      this.$route.name === 'Register' ||
      this.$route.name === 'Mapexample' ||
      this.$route.name === 'loginbycsite' ||
      this.$route.name === 'surveyguest'
      ) {
        return true;
      } else {
        return false;
      }
    },

    isHideFooter () {
      if (this.$route.name === 'Login' ||
      this.$route.name === 'Register' ||
      this.$route.name === 'LichenMap' ||
      this.$route.name === 'BioblitzMap' ||
      this.$route.name === 'Bioblitz2Map' ||
      this.$route.name === 'AgriculturalproductMap' ||
      this.$route.name === 'PhysicalhealthMap' ||
      this.$route.name === 'Physicalhealth2Map' ||
      this.$route.name === 'CropdamageproblemMap' ||
      this.$route.name === 'Sampleno2Map' ||
      this.$route.name === 'SamplerainMap' ||
      this.$route.name === 'SamplewaterMap' ||
      this.$route.name === 'OrangeorchardMap' ||
      this.$route.name === 'MercuryMap' ||
      this.$route.name === 'MercuryriskMap' ||
      this.$route.name === 'Mapexample' ||
      this.$route.name === 'SPST20Map' ||
      this.$route.name === 'SPST202Map' ||
      this.$route.name === 'SmokeandsoundMap' ||
      this.$route.name === 'loginbycsite' ||
      this.$route.name === 'surveyguest' ||
      this.$route.name === 'mercurygridmap'
      ) {
        return true;
      } else {
        return false;
      }
    },

    setContentClass () {
      if (this.$route.name === 'LichenMap' ||
      this.$route.name === 'BioblitzMap' ||
      this.$route.name === 'Bioblitz2Map' ||
      this.$route.name === 'AgriculturalproductMap' ||
      this.$route.name === 'PhysicalhealthMap' ||
      this.$route.name === 'Physicalhealth2Map' ||
      this.$route.name === 'CropdamageproblemMap' ||
      this.$route.name === 'Sampleno2Map' ||
      this.$route.name === 'SamplerainMap' ||
      this.$route.name === 'SamplewaterMap' ||
      this.$route.name === 'OrangeorchardMap' ||
      this.$route.name === 'MercuryMap' ||
      this.$route.name === 'MercuryriskMap' ||
      this.$route.name === 'Mapexample' ||
      this.$route.name === 'SPST20Map' ||
      this.$route.name === 'SPST202Map' ||
      this.$route.name === 'SmokeandsoundMap' ||
      this.$route.name === 'mercurygridmap'
      ) {
        return 'lichenMapArea';
      } else if (this.$route.name === 'Login' || this.$route.name === 'Register') {
        return 'loginContainer';
      } else {
        return '';
      }
    },

    getUsername () {
      this.currRouter = this.$route.name;

      if (localStorage.getItem('member')) {
        const data = localStorage.getItem('member');
        const memberData = JSON.parse(data);

        if (memberData.role === 'admin') {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }

        if (memberData.profileImg) {
          this.profileImg = memberData.profileImg.url ? memberData.profileImg.url : '/img/headmenu/anonymous.png';
        } else {
          this.profileImg = '/img/headmenu/anonymous.png';
        }

        this.isGuest = (!this.isAdmin && memberData.organizationID === this.globalVar.GuestOrganizationID);

        return memberData.username;
      } else {
        return 'Username';
      }
    },

    setActiveClass () {
      if (this.$route.name === 'mysurvey') {
        return 'lichenMapArea';
      } else if (this.$route.name === 'Login' || this.$route.name === 'Register') {
        return 'loginContainer';
      } else {
        return '';
      }
    },

    getMenuList () {
      this.currRouter = this.$route.name;
      const memberData = this.getMemberData();

      for (let i = 0; i < this.menuList.length; i++) {
        this.menuList[i].isShow = false;
        if (this.menuList[i].permission === 'admin') {
          if (memberData && memberData.role === 'admin') {
            this.menuList[i].isShow = true;
          }
        } else if (this.menuList[i].permission === 'superAdmin') {
          if (memberData && memberData.role === 'admin' && memberData.organizationID === this.globalVar.NCIDOrganizationID) {
            this.menuList[i].isShow = true;
          }
        } else {
          this.menuList[i].isShow = true;
        }
      }

      return this.menuList;
    }
  },
  mounted () {
    this.$i18n.locale = 'th';
    window.addEventListener('scroll', this.updateScroll);
    // this.initMemberData();
  }
};
</script>
