import { createStore } from 'vuex';
import apiModule from './api_module';
import authModule from './auth_module';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    api: apiModule,
    auth: authModule
  }
});
