// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';
import colors from 'vuetify/lib/util/colors';



/*
const myCustomLightTheme = {
  dark: false,
  colors: {
    background: '#FF00FF',
    xxa: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#6200EE',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    red: 'FFFFFF',
    warning: '#FB8C00'
  },
  variables: {}
};

const myCustomDarkTheme = {
  dark: false,
  colors: {
    background: '#00FF00',
    xxa: '#eaca44',
    surface: '#FFFFFF',
    primary: '#6200EE',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    red: 'FFFFFF',
    warning: '#FB8C00'
  },
  variables: {}
};

/*
export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  theme: false
});
*/

const csiteTheme = {
  dark: false,
  colors: {
    defaulttext:'#ffffff',
    bluetext:'#154c82',
    primary: '#1b526f',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#23a38a',
    warning: '#FB8C00',
    hello: '#555555',
    btnSubmit: '#1565C0',
    btnReset: '#DFDFDF',
    whiteOp4:'rgba(#fff, .4)',
    blackOp4:'rgba(#000, .4)',
  },
  variables: {}
};

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  theme: {
    dark: true,
    defaultTheme: 'csiteTheme',
    themes: {
      csiteTheme,
      light: {
        colors: {
          defaulttext:'#ffffff',
          bluetext:'#154c82',
          hello: '#FB8C00',
          btnSubmit: '#2196F3',
          btnReset: '#DFDFDF',
          whiteOp4:'rgba(255,255,255,0.4)',
          blackOp4:'rgba(0,0,0,0.4)',
        }
      },
      dark: {
        colors: {
          defaulttext:'#000',
          bluetext:'#154c82',
          primary: colors.red.base,
          secondary: colors.red.darken1,
          accent: colors.red.darken1,
          error: colors.red.accent3,
          background: colors.red.lighten5,
          info: colors.red.darken1,
          hello: colors.yellow.base,
          btnSubmit: colors.blue.darken4,
        }
      }
    }
  },
  display: {
    thresholds: {
      xs: 0,
      sm: 340,
      md: 540,
      lg: 800,
      xl: 1280,
    },
  },
});
