import HttpRequest from './core/http_request';

class MemberProvider extends HttpRequest {
  createMember (data) {
    return this.create('', data);
  }

  getStatus () {
    return this.request('get', '/status');
  }

  getMemberProfile (id) {
    return this.request('get', '/member/id/' + id);
  }

  updateProfile (data) {
    const id = data._id;
    delete data._id;
    return this.request('post', '/member/myaccount/' + id, data);
  }

  myAccount (data) {
    return this.request('get', '/member/myaccount/' + data._id);
  }

  getFullparent (data) {
    return this.request('get', '/organization/fullparent/' + data.organizationID, data);
  }

  uploadMemberFile (data) {
    return this.request('upload', '/uploadFile/uploadMemberFile', data);
  }
}

export default MemberProvider;
