import AuthProvider from './auth_service';
import MemberProvider from './member_service';
import SurveyProvider from './survey_service';
import AdminProvider from './admin_service';
import MasterProvider from './master_service';

export const authService = new AuthProvider('auth');
export const memberService = new MemberProvider('member');
export const surveyService = new SurveyProvider('survey');
export const adminService = new AdminProvider('admin');
export const masterService = new MasterProvider('master');
