/* global localStorage */

import HttpRequest from './core/http_request';

class AuthProvider extends HttpRequest {
  login (data) {
    return this.request('POST', '/auth/login', data).then(response => {
      if (response.data.token.accessToken) {
        localStorage.setItem('token', JSON.stringify(response.data.token));
        localStorage.setItem('member', JSON.stringify(response.data.member));
      }
      return response;
    });
  }

  logout () {
    localStorage.removeItem('member');
    localStorage.removeItem('token');
  }

  refreshToken (data) {
    return this.request('POST', '/auth/refresh-token', data);
  }

  register (data) {
    return this.request('POST', '/auth/register', data);
  }

  loginByCsite (data) {
    return this.request('POST', '/auth/loginByCsite', data).then(response => {
      if (response.data.token.accessToken) {
        localStorage.setItem('token', JSON.stringify(response.data.token));
        localStorage.setItem('member', JSON.stringify(response.data.member));
      }
      return response;
    });
  }
}

export default AuthProvider;
